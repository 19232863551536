import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import ReactMoment from "react-moment";

import {
  eventTime,
  eventDetails,
  eventCard,
} from "../../css/event-summary.module.css";

const EventSummary = ({ details }) => {
  const {
    eventTitle,
    eventDescription,
    eventDayOfWeek,
    eventStart,
    eventEnd,
    mainImage,
    eventLink,
  } = details;
  const maybeWebsite = eventLink ? (
    <p>
      Website: <a href={eventLink}>{eventTitle}</a>
    </p>
  ) : null;
  return (
    <div>
      <div className={eventCard}>
        <div>
          <GatsbyImage
            image={mainImage.childImageSharp.gatsbyImageData}
            alt={eventTitle}
            imgStyle={{ objectFit: "cover" }}
            style={{ height: "215px" }}
          />
        </div>
        <div className={eventDetails}>
          <h2 className="info">{eventTitle}</h2>
          <ReactMarkdown source={eventDescription} />
          <p className={eventTime}>
            {eventDayOfWeek}&nbsp;
            <ReactMoment format="HH:mm">{eventStart}</ReactMoment> -{" "}
            <ReactMoment format="HH:mm">{eventEnd}</ReactMoment>
          </p>
          {maybeWebsite}
        </div>
      </div>
    </div>
  );
};

export default EventSummary;
