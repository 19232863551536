import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Event from "../components/events/eventSummary";
import RoomLegend from "../components/rooms/roomLegend";

import {
  eventsContact,
  regularEventWrapper,
  eventsRegHeader,
  eventsRoomLegend,
  eventsCalendar,
  eventsHeader,
} from "../css/calendar.module.css";

const EventPage = ({ data }) => {
  const {
    allStrapiEvent: { edges },
  } = data;

  const regular = edges.filter((event) => event.node.regularEvent);
  // const noreg = edges.filter((event) => !event.node.regularEvent);

  return (
    <>
      <Helmet>
        <title>Calendar | Pannal Village Hall</title>
      </Helmet>
      <div className={eventsHeader}>
        <h1>Calendar</h1>
        <p>
          Use our calendar to find your slot and then go to{" "}
          <Link to="/contact">Bookings</Link> to let us know your needs
        </p>
      </div>
      <div className={eventsCalendar}>
        <iframe
          src={data.strapiWhatsOnCalendar.whatsOnCalendarLink}
          title="events calendar"
        ></iframe>
      </div>

      <div className={eventsRoomLegend}>
        <RoomLegend />
      </div>

      <div className={eventsRegHeader}>
        <h2>Our regular activities</h2>
      </div>
      <div className={regularEventWrapper}>
        {regular.map((event) => (
          <Event details={event.node} key={event.node.id} />
        ))}
      </div>
      <div className={eventsContact}>
        <p>
          <Link to="/contact">Get in touch</Link> with us to discuss setting up
          your own regular event
        </p>
      </div>
    </>
  );
};

export default EventPage;

export const query = graphql`
  {
    allStrapiEvent {
      edges {
        node {
          id
          eventTitle
          eventDayOfWeek
          eventDescription
          eventEnd
          eventStart
          regularEvent
          eventLink
          slug
          mainImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiWhatsOnCalendar {
      whatsOnCalendarLink
    }
  }
`;
